<script setup>
import FluencyLoader from 'core-ui/components/common/fluencyLoader'
import { ref, computed, nextTick } from 'vue'
import { usePartnerPolling } from '../../composables/usePartnerPolling'

const nuxtApp = useNuxtApp()
const { addPendingWork, getPendingWork } = usePartnerPolling()

const props = defineProps(['errorMessage', 'details'])
const emits = defineEmits(['detailsExpanded'])

const internalUser = computed(() => nuxtApp.$store.getters?.user?.internalUser)
const showStackTrace = ref(false)
const showRawMessage = ref(false)
const showAISuggestion = ref(false)
const suggestion = ref(null)
const suggestionLoading = ref(false)
const showResubmit = computed(() => {
  return !['insertionOrder', 'lineItem'].includes(props?.details?.type)
})
const pollingPlanId = computed(() => {
  for (const prop in props.details.partnerInstance) {
    if (props.details.partnerInstance.hasOwnProperty(prop)) {
      if (prop.indexOf('PlanId') !== -1) {
        return props.details.partnerInstance[prop]
      }
    }
  }
  return null
})
const pollingPlanType = computed(() => {
  let modType = props.details.type.charAt(0).toUpperCase() + props.details.type.slice(1)
  if (modType === 'Ad') {
    modType = 'Creative'
  }
  modType = modType + 'Plan'
  return modType
})

const showLoader = computed(() => {
  const pendingWork = getPendingWork({ type: pollingPlanType.value, planId: pollingPlanId.value })
  return (typeof pendingWork?.pendingOperations !== 'undefined') || pendingWork?.pendingOperations?.length > 0
})

const loadSuggestion = async () => {
  showAISuggestion.value = !showAISuggestion.value
  showStackTrace.value = false
  showRawMessage.value = false

  if (suggestion.value) {
    await nextTick()
    emits('detailsExpanded', { type: 'aiSuggestion', expanded: true })
    return false // already loaded
  }

  suggestionLoading.value = true

  if (props.details.accountPlanId && props.details.type && props.details.partnerInstance) {
    let modType = props.details.type.charAt(0).toUpperCase() + props.details.type.slice(1)
    if (modType === 'Ad') {
      modType = 'Creative'
    }
    const resp = await nuxtApp.$res.fetch.errorMessageSuggest(modType, props.details.accountPlanId, props.details.partnerInstance)
    if (resp) {
      suggestion.value = resp
    }
  }

  suggestionLoading.value = false
  await nextTick()
  emits('detailsExpanded', { type: 'aiSuggestion', expanded: true })
}
const resubmit = async () => {
  if (props.details.accountPlanId && props.details.type && props.details.partnerInstance) {
    let modType = props.details.type.charAt(0).toUpperCase() + props.details.type.slice(1)
    if (modType === 'Ad') {
      modType = 'Creative'
    }
    const resp = await nuxtApp.$res.fetch.errorMessageResubmit(modType, props.details.accountPlanId, props.details.partnerInstance)
    if (resp) {
      console.log('Resubmit successful')

      // start polling process
      /*
      {
      "fluentJobId": 100000014692008672,
      "streamId": 939143,
      "accountPlanId": 13045,
      "partnerId": 1,
      "objectType": "CampaignPlan",
      "objectId": 1659996,
      "jobType": "SET",
      "priority": -245,
      "status": 0,
      "dateRequested": "2024-05-09T12:20:28.081034",
      "requestedBy": "api",
      "apiLogSessionId": "d5d7a6b7"
      }

      [
          {
              "planId": 1659996,
              "partnerId": 1,
              "type": "CampaignPlan",
              "pendingOperations": [
                  "SET"
              ]
          }
      ]
       */
      const synthesizedPendingWork = [{
        planId: resp.objectId,
        partnerId: resp.partnerId,
        type: resp.objectType,
        pendingOperations: [resp.jobType]
      }]
      addPendingWork(synthesizedPendingWork)
      nuxtApp.$store.dispatch('fetchPlansWithMetrics', { fetchType: modType.toLowerCase(), forceBudgetReload: false })
    }
  }
}
const toggleStackTrace = async () => {
  showStackTrace.value = !showStackTrace.value
  showAISuggestion.value = false
  showRawMessage.value = false
  await nextTick()
  emits('detailsExpanded', { type: 'stackTrace', expanded: showStackTrace.value })
}
const toggleRawMessage = async () => {
  showRawMessage.value = !showRawMessage.value
  showAISuggestion.value = false
  showStackTrace.value = false
  await nextTick()
  emits('detailsExpanded', { type: 'rawMessage', expanded: showRawMessage.value })
}
</script>
<template>
  <div class="text-110">
    <div class="d-flex-start">
      <div class="flex-grow-1"><p>{{errorMessage.message}}</p></div>
      <div v-if="showResubmit" class="ml-4">
        <button class="btn btn-primary" :class="{'disabled': showLoader}"
                @click="resubmit()">
          <div v-if="showLoader" style="width:52px;"><fluency-icon type="loop" class="rotating" /></div>
          <span v-else>Resubmit</span>
        </button>
      </div>
    </div>

    <div class="mb-3">
      <div class="cursor-pointer"
           @click="loadSuggestion()">
        <fluency-icon type="chevronRight"
                      class="mr-2"
                      :class="{ 'rotate-right-90': showAISuggestion }" /> Muse AI Suggestions
      </div>
      <fluency-loader v-if="suggestionLoading" dots color />
      <div v-if="suggestion && showAISuggestion" class="partner-error-content-block mx-4 my-3 overflow-auto border rounded p-3">
        <pre class="white-space-pre-line">{{suggestion[0]}}</pre>
      </div>
    </div>

    <div v-if="errorMessage.rawMessage && internalUser" class="mb-3">
      <div class="cursor-pointer"
           @click="toggleRawMessage()">
        <fluency-icon type="chevronRight"
                      class="mr-2"
                      :class="{ 'rotate-right-90': showRawMessage }" /> Raw Message
      </div>
      <div v-if="showRawMessage" class="partner-error-content-block mx-4 my-3 overflow-auto border rounded p-3">
        <pre>{{JSON.stringify(errorMessage.rawMessage, null, 2)}}</pre>
      </div>
    </div>

    <div v-if="errorMessage.stackTrace && internalUser" class="mb-3">
      <div class="cursor-pointer"
           @click="toggleStackTrace()">
        <fluency-icon type="chevronRight"
                      class="mr-2"
                      :class="{ 'rotate-right-90': showStackTrace }" /> Stack Trace
      </div>
      <div v-if="showStackTrace" class="partner-error-content-block mx-4 my-3 overflow-auto border rounded p-3">
        <code>{{errorMessage.stackTrace}}</code>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .partner-error-content-block {
    max-height: 300px;
  }
</style>
