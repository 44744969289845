<template>
  <p-popover :pt="{ root: { class: `popover-${target} partner-error-popover`}}"
             ref="partnerErrorPopover"
             @show="handleShow"
             @hide="handleHidden">

    <div v-if="props" class="d-flex justify-content-between align-items-center border-bottom pb-2 mb-4">
      <span class="flex-grow-1">Error Message</span>
      <a v-if="copyText.length > 0" v-click-copy="copyText">
        <fluency-icon type="copy" />
      </a>
      <a v-if="props.href" :href="props.href" target="_blank" class="ml-3">
        <fluency-icon type="linkOut" />
      </a>
      <a class="ml-3 cursor-pointer" @click="hide()">
        <fluency-icon type="close" />
      </a>
    </div>
    <template v-if="props">
      <fluency-loader v-if="loading" dots class="mx-5 px-5" />
      <div v-else-if="errorMessage.message && !internalUser">
        <div>{{errorMessage.message}}</div>
        <div>
          <span class="font-weight-bold">Reference ID </span>
          <span> {{errorMessage.referenceId}}</span>
        </div>
      </div>
      <pre v-else-if="!errorMessage.message" class="white-space-pre-line">
        {{$filters.truncate(props.lastError, 240)}}
      </pre>
    </template>
    <partner-error-popover-details :error-message="errorMessage"
                                   :details="{ accountPlanId: props.accountPlanId, type: props.type, partnerInstance: props.partnerInstance }"
                                  @detailsExpanded="partnerErrorPopover.alignOverlay()"/>
  </p-popover>

</template>

<script setup>
import FluencyLoader from 'core-ui/components/common/fluencyLoader'
import partnerErrorPopoverDetails from '@/components/common/partnerErrorPopoverDetails'
import { ref, computed, reactive } from 'vue'
const nuxtApp = useNuxtApp()

const props = defineProps({
  accountPlanId: {
    required: true
  },
  type: {
    required: true
  },
  partnerInstance: {
    required: true
  },
  lastError: {
    required: true
  },
  href: {
    required: false
  }
})

const loading = ref(false)
const showStackTrace = ref(false)
const partnerErrorPopover = ref(null)

const errorMessage = reactive({
  message: '',
  referenceId: '',
  stackTrace: '',
  suggestion: ''
})

const show = ($nativeEvent, targetEl = null) => {
  partnerErrorPopover.value.show($nativeEvent, targetEl)
}
const toggle = ($nativeEvent, targetEl = null) => {
  partnerErrorPopover.value.toggle($nativeEvent, targetEl)
}
const hide = () => {
  partnerErrorPopover.value.hide()
}
defineExpose({ show, toggle, hide })

const handleShow = async () => {
  await loadErrorMessage(props.accountPlanId, props.type, props.partnerInstance)
  partnerErrorPopover.value.alignOverlay()
}

const handleHidden = () => {
  errorMessage.message = ''
  errorMessage.referenceId = ''
  errorMessage.stackTrace = ''
}
const formatStackTrace = (message) => {
  if (message) {
    const regex = /[A-Z].*$/gm
    const matches = message.toString().match(regex)
    return matches.join('\n')
  }
}
const loadErrorMessage = async (accountPlanId, type, partnerInstance) => {
  if (accountPlanId && type && partnerInstance) {
    let modType = type.charAt(0).toUpperCase() + type.slice(1)
    if (modType === 'Ad') {
      modType = 'Creative'
    }
    const resp = await nuxtApp.$res.fetch.errorMessage(modType, accountPlanId, partnerInstance)
    if (resp) {
      errorMessage.message = resp.message
      errorMessage.rawMessage = resp.rawMessage
      errorMessage.referenceId = resp.referenceId
      errorMessage.stackTrace = formatStackTrace(resp.stackTrace)
    }
  }
}

const internalUser = computed(() => nuxtApp.$store.getters?.user?.internalUser)

const copyText = computed(() => {
  if (!errorMessage.message) return props.lastError
  let text = `Message: ${errorMessage.message}\nReference ID: ${errorMessage.referenceId}`
  if (errorMessage.stackTrace && internalUser && showStackTrace.value) {
    text = `${text}\n${errorMessage.stackTrace}`
  }
  return text
})

</script>
